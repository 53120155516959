import Vue from "vue";
import Vuex from "vuex";

import VuexPersist from "vuex-persist";
import breadcrumbs from "./breadcrumbs.module";
import claim from "./claim.module";
import client from "./client.module";
import contents from "./content.module";
import jwt from "./jwt.module";
import locale from "./locale.module";
import morStore from "./morStore.module";
import participant from "./participant.module";
import program from "./program.module";
import storeProgram from "./storeProgram.module";
import user from "./user.module";

const vuexLocalStorage = new VuexPersist({
  key: "gapp-vuex",
  storage: window.localStorage,

  // Only keep certain state variables in local storage.  For the rest, recalculate
  reducer: state => ({
    jwt: state.jwt,
    user: state.user,
    client: state.client,
    morStore: state.morStore,
    program: state.program,
    participant: state.participant,
    claim: state.claim,
    storeProgram: state.storeProgram,
    locale: state.locale,
    contents: state.content
  })
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    jwt,
    user,
    breadcrumbs,
    client,
    morStore,
    program,
    participant,
    claim,
    storeProgram,
    locale,
    contents
  },
  plugins: [vuexLocalStorage.plugin]
});
