// action types
export const SELECT_MOR_STORE = "selectMorStore";
export const UNSELECT_MOR_STORE = "unselectMorStore";

// mutation types
export const SET_MOR_STORE = "setMorStore";

export default {
  state: {
    morStore: null
  },
  getters: {
    /**
     * Get current selected morStore
     * @param state
     * @returns {*}
     */
    selectedMorStore(state) {
      return state.morStore;
    }
  },
  actions: {
    /**
     * Select morStore
     * @param state
     * @param payload
     */
    [SELECT_MOR_STORE](state, payload) {
      state.commit(SET_MOR_STORE, payload);
    },

    /**
     * Unselect morStore
     * @param state
     * @param payload
     */
    [UNSELECT_MOR_STORE](state) {
      state.commit(SET_MOR_STORE, null);
    }
  },
  mutations: {
    [SET_MOR_STORE](state, payload) {
      state.morStore = payload;
    }
  }
};
