import UtilService from "./util.service";

const morStoreService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },
  renderMorFeeText() {
    let morStore = this.store.getters.selectedMorStore;
    if (morStore && morStore.gappMorTransactionPercentageFee != null && morStore.gappMorTransactionFlatFee != null) {
      let percentFee = UtilService.toNumber(morStore.gappMorTransactionPercentageFee * 100.0, 2, this.selectedProgram);
      let flatFee = UtilService.toNumber(morStore.gappMorTransactionFlatFee, 2, this.selectedProgram);
      let fee =
        percentFee +
        "% + " +
        flatFee +
        (morStore.currency && morStore.currency.name ? " " + morStore.currency.name : "");
      return fee;
    } else {
      return "";
    }
  },
  renderForcedPlatformFeeText() {
    let morStore = this.store.getters.selectedMorStore;
    if (
      morStore &&
      morStore.forcePlatformMorTransactionPercentageFee != null &&
      morStore.forcePlatformMorTransactionFlatFee != null
    ) {
      let percentFee = UtilService.toNumber(
        morStore.forcePlatformMorTransactionPercentageFee * 100.0,
        2,
        this.selectedProgram
      );
      let flatFee = UtilService.toNumber(morStore.forcePlatformMorTransactionFlatFee, 2, this.selectedProgram);
      let fee =
        percentFee +
        "% + " +
        flatFee +
        (morStore.currency && morStore.currency.name ? " " + morStore.currency.name : "");
      return fee;
    } else {
      return "";
    }
  },
  renderGenericFeeText() {
    let morStore = this.store.getters.selectedMorStore;
    if (morStore && morStore.genericTransactionPercentageFee != null && morStore.genericTransactionFlatFee != null) {
      let percentFee = UtilService.toNumber(morStore.genericTransactionPercentageFee * 100.0, 2, this.selectedProgram);
      let flatFee = UtilService.toNumber(morStore.genericTransactionFlatFee, 2, this.selectedProgram);
      let fee =
        percentFee +
        "% + " +
        flatFee +
        (morStore.currency && morStore.currency.name ? " " + morStore.currency.name : "");
      return fee;
    } else {
      return "";
    }
  }
};

export default morStoreService;
