<template>
  <div>
    <v-text-field
      @click="onClick"
      @click:clear="onClear"
      prepend-icon="mdi-calendar"
      readonly
      v-model="tzDateTimeValue"
      v-bind:class="{ required: required }"
      :clearable="!readonly && !disabled"
      v-bind="{ ...$attrs }"
      :rules="rulesArray"
      :disabled="disabled"
    ></v-text-field>
    <v-dialog v-model="showDialog" width="unset">
      <v-card>
        <v-card-title class="justify-center"> {{ tzDateTimeValue }} </v-card-title>
        <v-card-text>
          <v-tabs centered>
            <v-tab>
              <v-icon>mdi-calendar</v-icon>
            </v-tab>
            <v-tab v-if="!onlyDate">
              <v-icon>mdi-clock</v-icon>
            </v-tab>
            <v-tab-item>
              <v-date-picker
                class="mt-2"
                v-model="tzDateValue"
                @input="onInput"
                :pickerDate.sync="shownDate"
                landscape
                :max="formattedMaxDate"
              ></v-date-picker>
            </v-tab-item>
            <v-tab-item v-if="!onlyDate">
              <v-time-picker class="mt-2" @input="onInput" format="ampm" v-model="tzTimeValue" scrollable landscape>
                <template v-slot:default>
                  <v-container>
                    <v-row>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                          <v-btn
                            class="ml-1"
                            @click="onStartOfDay()"
                            v-bind="{ ...attrsTooltip }"
                            v-on="{ ...onTooltip }"
                            ><v-icon>mdi-clock-start</v-icon></v-btn
                          >
                        </template>
                        <span>Start of Day</span>
                      </v-tooltip>
                      <v-spacer></v-spacer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                          <v-btn class="mr-1" @click="onEndOfDay()" v-bind="{ ...attrsTooltip }" v-on="{ ...onTooltip }"
                            ><v-icon>mdi-clock-end</v-icon></v-btn
                          >
                        </template>
                        <span>End of Day</span>
                      </v-tooltip>
                    </v-row>
                    <v-row v-if="local">
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="selectedTimezone"
                          :items="timezonesList"
                          label="Timezone"
                          @input="onInput"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
              </v-time-picker>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary"
            @click="
              showDialog = false;
              $emit('dateTimeChange');
            "
            :disabled="!returnValue"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import UtilService from "../../services/util.service";

export default {
  name: "DateTimePickerField",
  props: {
    value: String,
    required: Boolean,
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    format: { type: String, default: "MM/DD/YYYY hh:mm a z" },
    returnFormat: { type: String, default: "YYYY-MM-DDTHH:mm:ss.SSSZ" },
    dateFormat: { type: String, default: "MM/DD/YYYY" },
    timeFormat: { type: String, default: "hh:mm a z" },
    defaultTime: null,
    local: { type: Boolean, default: false },
    onlyDate: { type: Boolean, default: false },
    min: String,
    max: String,
    rules: {
      default: () => [],
      type: Array
    }
  },
  data: () => ({
    utcDateTime: "", // moment object with UTC
    utcDateValue: "", // string YYYY-MM-DD
    utcTimeValue: "", // string HH:mm

    tzDateTime: "", // moment object with TZ
    tzDateValue: "", // string YYYY-MM-DD
    tzTimeValue: "", // string HH:mm

    // ISO 8601 Format
    isoDateFormat: "YYYY-MM-DD",
    isoTimeFormat: "HH:mm:ss",

    tzDateTimeValue: "",

    // dialog
    shownDate: null,
    showDialog: false,

    // timezones
    timezonesList: [],
    selectedTimezone: null,

    rulesArray: []
  }),
  methods: {
    onStartOfDay() {
      this.tzTimeValue = "00:00:00";
      this.onInput();
    },
    onEndOfDay() {
      this.tzTimeValue = "23:59:59";
      this.onInput();
    },
    onClick() {
      if (!this.readonly && !this.disabled) {
        this.showDialog = true;
      }
    },
    onClear() {
      if (!this.readonly && !this.disabled) {
        this.tzDateValue = "";
        this.tzTimeValue = "";
        this.onInput();
      }
    },
    setTzDateTimeValue() {
      let v = null;
      if (this.tzDateValue && this.tzDateValue.length > 0 && this.tzTimeValue && this.tzTimeValue.length > 0) {
        let tzDateTime = moment.tz(
          this.tzDateValue + " " + this.tzTimeValue,
          this.isoDateFormat + " " + this.isoTimeFormat,
          this.selectedTimezone
        );
        v = tzDateTime.format(this.format);
      }
      this.tzDateTimeValue = v;
    },
    refreshDateTimeVariables(value) {
      // utc date / time
      this.utcDateTime = value && value.length > 0 ? moment.utc(value) : undefined;
      this.utcDateValue = this.utcDateTime ? this.utcDateTime.format(this.isoDateFormat) : "";
      this.utcTimeValue = this.utcDateTime ? this.utcDateTime.format(this.isoTimeFormat) : "";

      // tz date / time
      let onlyDate = value && value.length == 10;
      if (onlyDate) {
        this.tzDateTime = moment(value);
      } else {
        this.tzDateTime = this.utcDateTime ? this.utcDateTime.tz(this.selectedTimezone) : undefined;
      }
      this.tzDateValue = this.tzDateTime ? this.tzDateTime.format(this.isoDateFormat) : "";

      if (!this.utcDateTime && this.defaultTime) {
        this.tzTimeValue = this.defaultTime;
      } else {
        this.tzTimeValue = this.tzDateTime ? this.tzDateTime.format(this.isoTimeFormat) : "00:00:00";
      }
    },
    onInput() {
      this.setTzDateTimeValue();
      this.$emit("input", this.returnValue);
    },
    validateDate(v) {
      if (this.required) {
        if (!v) {
          return "Required";
        }
        var date = UtilService.parseDateClient(v, this.format, this.selectedClient);
        if (!date.isValid()) {
          return "Invalid date. Expected format " + this.format + ".";
        } else {
          if (this.min || this.max) {
            let minDate = this.min ? moment(this.min) : undefined;
            let maxDate = this.max ? moment(this.max) : undefined;
            if ((minDate && date.isBefore(minDate)) || (maxDate && date.isAfter(maxDate))) {
              let errorMessage = "Invalid Date. ";
              if (minDate && maxDate) {
                errorMessage +=
                  "Date should be between " +
                  UtilService.formatDateClient(minDate.utc().format(), this.format, this.selectedClient) +
                  " and " +
                  UtilService.formatDateClient(maxDate.utc().format(), this.format, this.selectedClient) +
                  ".";
              } else if (minDate) {
                errorMessage +=
                  "Date should be after " +
                  UtilService.formatDateClient(minDate.utc().format(), this.format, this.selectedClient);
              } else {
                errorMessage +=
                  "Date should be before " +
                  UtilService.formatDateClient(maxDate.utc().format(), this.format, this.selectedClient) +
                  ".";
              }
              return errorMessage;
            }
          }
        }
      }
      return true;
    }
  },
  computed: {
    ...mapGetters(["selectedClient"]),

    returnValue() {
      let value = null;
      if (this.tzDateValue && this.tzDateValue.length > 0 && this.tzTimeValue && this.tzTimeValue.length > 0) {
        let tzDateTime = moment.tz(
          this.tzDateValue + " " + this.tzTimeValue,
          this.isoDateFormat + " " + this.isoTimeFormat,
          this.selectedTimezone
        );
        let utcDateTime = tzDateTime.utc();
        value = utcDateTime.format(this.returnFormat);
      }
      return value;
    },

    formattedMaxDate() {
      return this.max ? moment(this.max).format("YYYY-MM-DD") : undefined;
    }
  },
  watch: {
    value(v) {
      this.refreshDateTimeVariables(v);
      this.setTzDateTimeValue();
      this.$emit("input", this.returnValue);
    },
    rules: {
      deep: true,
      immediate: true,
      handler(v) {
        this.rulesArray = [v => this.validateDate(v)].concat(v);
      }
    }
  },
  created() {
    // list of timezones
    this.timezonesList = moment.tz.names();

    // if client is provided and has a timezone, then use it, otherwise, use guess
    if (this.local) {
      this.selectedTimezone = moment.tz.guess();
    } else if (this.selectedClient && this.selectedClient.timezone) {
      this.selectedTimezone = this.selectedClient.timezone;
    } else {
      this.selectedTimezone = moment.tz.guess();
    }

    // set dates variables
    this.refreshDateTimeVariables(this.value);

    this.setTzDateTimeValue();
  }
};
</script>
