import { STORE_TOKEN, REMOVE_TOKEN } from "../store/jwt.module";

const JwtService = {
  store: null,
  init(opts) {
    if (opts.store) {
      this.store = opts.store;
    }
  },
  getToken() {
    return this.store.getters.token;
  },

  saveToken(token) {
    return this.store.dispatch(STORE_TOKEN, token);
  },

  destroyToken() {
    return this.store.dispatch(REMOVE_TOKEN);
  }
};

export default JwtService;
