var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(" Participant Management "),(this.organization)?_c('v-chip',{staticClass:"ma-2",attrs:{"close":""},on:{"click:close":function($event){return _vm.clearOrganization()}}},[_vm._v(" "+_vm._s(this.organization.name)+" ")]):_vm._e()],1),_c('v-spacer'),_c('v-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"label":_vm.$i18n.translate('Filter') + ' ' + _vm.$i18n.translate('Participants'),"flat":"","solo-inverted":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},on:{"click:clear":_vm.onClear},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","fab":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Advanced Search")))])])]}}])},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$i18n.translate("Advanced Search")))]),_c('v-list-item',{attrs:{"selectable":""}},[_c('v-list-item-content',[_c('v-select',{attrs:{"dense":"","items":_vm.effectiveItems,"label":_vm.$i18n.translate('Active') + ' / ' + _vm.$i18n.translate('Inactive'),"clearable":""},on:{"input":_vm.onSubmit},model:{value:(_vm.filterByEffective),callback:function ($$v) {_vm.filterByEffective=$$v},expression:"filterByEffective"}})],1)],1),_c('v-list-item',{attrs:{"selectable":""}},[_c('v-list-item-content',[_c('ParticipantTypesField',{attrs:{"dense":"","label":"Participant Types"},on:{"input":_vm.onSubmit},model:{value:(_vm.filterByParticipantTypes),callback:function ($$v) {_vm.filterByParticipantTypes=$$v},expression:"filterByParticipantTypes"}})],1)],1)],1)],1)],1),(_vm.hasFilters)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"small":"","fab":""},on:{"click":_vm.onClearSearch}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-magnify-close")])],1)]}}],null,false,3915488936)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Clear Search")))])]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loading,"mobile-breakpoint":"800","footer-props":{ 'items-per-page-options': [10, 25, 50] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.organization.name",fn:function(ref){
var item = ref.item;
return [(item.organization)?_c('span',[_vm._v(" "+_vm._s(item.organization.name)+" ")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status.description)+" ")]}},{key:"item.user.lastSuccessfulLoginDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateFromNow")(item.user.lastSuccessfulLoginDate))+" ")]}},{key:"item.missingRequiredInformation",fn:function(ref){
var item = ref.item;
return [(!item.missingRequiredInformation)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.isSelectedProgramEndUser)?_c('v-container',[(_vm.cantImpersonateReason(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","small":"","color":"warning"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-switch-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.cantImpersonateReason(item)))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.onImpersonate(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-switch-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Impersonate")])])],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }