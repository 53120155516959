let promotion = null;
import ApiService from "../../../services/api.service";

export async function fetchPromotionById(id) {
  await ApiService.get("/api/promotions/" + id).then(({ data }) => {
    promotion = data;
  });

  await Promise.all([
    fetchPromotionTypeForPromotion(promotion),
    fetchClaimTypeForPromotion(promotion),
    fetchPromotionProductsForPromotion(promotion),
    fetchPromotionDeadlinesForPromotion(promotion)
  ]);
  return promotion;
}

export async function fetchPublicPromotionById(id) {
  await ApiService.get("/api/promotions/" + id + "/public").then(({ data }) => {
    promotion = data;
  });

  return promotion;
}

export async function fetchPublicPromotionByKey(key) {
  await ApiService.get("/api/promotions/byPromotionKey/" + key + "/public").then(({ data }) => {
    promotion = data;
  });

  return promotion;
}

export function fetchPromotionTypeForPromotion(promotion) {
  return ApiService.getRelatedObject("promotionType", promotion).then(({ data }) => {
    promotion.promotionType = data;
    if (promotion.promotionType.uploadLimit > 0) {
      promotion.promotionTypeDocumentationRequired = true;
    }
  });
}

export function fetchClaimTypeForPromotion(promotion) {
  return ApiService.getRelatedObject("claimType", promotion).then(({ data }) => {
    promotion.claimType = data;
  });
}

export async function fetchPromotionProductsByPromotionId(promotionId) {
  let allProducts = [];
  let pageSize = 100;
  let currentPage = 0;
  let totalPages = 1;

  while (currentPage < totalPages) {
    const response = await ApiService.post(
      `/api/promotionProducts/search?size=${pageSize}&page=${currentPage}&nestedSort=product.productKey,ASC`,
      {
        promotion: { id: promotionId },
        isEffective: true
      }
    );
    const data = response.data;
    allProducts = allProducts.concat(data.content);
    totalPages = data.totalPages;
    currentPage++;
  }
  const effectiveProducts = allProducts.filter(item => item.effective === true); // check if neccesaty after iseffective in true
  return effectiveProducts;
}

export async function fetchPromotionProductsForPromotion(promotion) {
  promotion.promotionProducts = await fetchPromotionProductsByPromotionId(promotion.id);
}

export function fetchPromotionDeadlinesForPromotion(promotion) {
  return ApiService.post("/api/promotionDeadlines/search", { promotion: { id: promotion.id } }).then(({ data }) => {
    promotion.promotionDeadlines = data;
  });
}
