// action types
export const STORE_TOKEN = "storeToken";
export const REMOVE_TOKEN = "removeToken";

// mutation types
export const SET_TOKEN = "setToken";

const state = {
  token: {}
};

const getters = {
  token(state) {
    return state.token;
  }
};

const actions = {
  [STORE_TOKEN](context, token) {
    context.commit(SET_TOKEN, token);
  },
  [REMOVE_TOKEN](context) {
    context.commit(SET_TOKEN, null);
  }
};

const mutations = {
  [SET_TOKEN](state, token) {
    state.token = token;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
