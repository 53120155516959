<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>Closed Claims</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-switch
        class="pt-7 mr-2"
        :label="$i18n.translate('Follow Up') + '?'"
        v-model="followUp"
        @change="onSubmit"
      ></v-switch>
      <v-form @submit.stop.prevent="onSubmit">
        <v-text-field
          class="mr-2"
          v-model="search"
          :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Claims')"
          flat
          solo-inverted
          hide-details
          clearable
          clear-icon="mdi-close-circle-outline"
          @click:clear="onClear"
        ></v-text-field>
      </v-form>
      <v-tooltip bottom v-if="hasFilters">
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn icon v-on="{ ...onTooltip }" @click="onClearSearch">
            <v-icon>mdi-magnify-close</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Clear Search") }}</span>
      </v-tooltip>
      <template v-slot:extension>
        <v-tabs v-model="selectedClaimTypeCountIndex" v-if="claimTypeCounts">
          <v-tab
            v-for="(claimTypeCount, i) in claimTypeCounts"
            :key="i"
            @click="onSelectClaimTypeCount(claimTypeCount)"
          >
            <v-badge color="primary" :content="claimTypeCount.count" v-if="claimTypeCount.claimTypeId">
              {{ claimTypeCount.claimTypeName }}
            </v-badge>
            <span v-else>
              {{ claimTypeCount.claimTypeName }}
            </span>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="3">
            <v-list dense v-if="selectedClaimTypeCount && selectedClaimTypeCount.claimTypeId">
              <v-subheader>Filter by Stage</v-subheader>
              <v-list-item-group v-model="selectedClaimStageCountIndex" color="primary">
                <v-list-item
                  v-for="(claimStageCount, i) in claimStageCounts"
                  :key="i"
                  @click="onSelectClaimStageCount(claimStageCount)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="claimStageCount.claimStageName"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="claimStageCount.count">
                    <v-list-item-action-text>
                      <v-badge left color="primary">
                        <span slot="badge">{{ claimStageCount.count }}</span>
                      </v-badge>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
              <v-subheader>Filter by Award Vehicle</v-subheader>
              <v-list-item-group v-model="selectedAwardVehicleCountIndex" color="primary">
                <v-list-item
                  v-for="(awardVehicle, i) in awardVehicleCounts"
                  :key="i"
                  @click="onSelectAwardVehicleCount(awardVehicle)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="awardVehicle.awardVehicleName"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="awardVehicle.count">
                    <v-list-item-action-text>
                      <v-badge left color="primary">
                        <span slot="badge">{{ awardVehicle.count }}</span>
                      </v-badge>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
              <v-subheader>Filter by Company</v-subheader>
              <v-list-item-group v-model="selectedOrganizationCountIndex" color="primary">
                <v-list-item
                  v-for="(organizationCount, i) in organizationCounts"
                  :key="i"
                  @click="onSelectOrganizationCount(organizationCount)"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="organizationCount.organizationName"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="organizationCount.count">
                    <v-list-item-action-text>
                      <v-badge left color="primary">
                        <span slot="badge">{{ organizationCount.count }}</span>
                      </v-badge>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-list dense v-else>
              <v-subheader>First select a Claim Type</v-subheader>
            </v-list>
          </v-col>
          <v-col cols="9">
            <span
              v-if="
                selectedClaimTypeCount ||
                  selectedOrganizationCount ||
                  selectedClaimStageCount ||
                  selectedAwardVehicleCount
              "
            >
              Showing:
              <v-chip close @click:close="onClearClaimTypeCount" v-if="selectedClaimTypeCount">
                {{ selectedClaimTypeCount.claimTypeName }}
              </v-chip>
              <v-chip close @click:close="onClearClaimStageCount" v-if="selectedClaimStageCount">
                {{ selectedClaimStageCount.claimStageName }}
              </v-chip>
              <v-chip close @click:close="onClearAwardVehicleCount" v-if="selectedAwardVehicleCount">
                {{ selectedAwardVehicleCount.awardVehicleName }}
              </v-chip>
              <v-chip close @click:close="onClearOrganizationCount" v-if="selectedOrganizationCount">
                {{ selectedOrganizationCount.organizationName }}
              </v-chip>
            </span>
            <v-data-table
              :items="items"
              :headers="headers"
              :server-items-length="total"
              :options.sync="options"
              :loading="loading"
              :footer-props="{ 'items-per-page-options': [10, 25, 50] }"
            >
              <template v-slot:item.claimKey="{ item }">
                <router-link :to="toClaimDetailManagement(item)">
                  {{ item.claimKey }}
                </router-link>
              </template>
              <template v-slot:item.participant.user.firstName="{ item }">
                {{ item.participant.user.fullName }}
              </template>
              <template v-slot:item.calculatedTotalClaimAmount="{ item }">
                {{ item.calculatedCalculatedTotalClaimAmount | toNumber(2, selectedProgram) }}
                {{
                  item.calculatedCalculatedTotalClaimAmountCurrency
                    ? item.calculatedCalculatedTotalClaimAmountCurrency
                    : ""
                }}
              </template>

              <template v-slot:item.updatedDate="{ item }">
                {{ item.updatedDate | formatDateFromNow }}
              </template>
              <template v-slot:item.createdDate="{ item }">
                {{ item.createdDate | formatDateFromNow }}
              </template>
              <template v-slot:item.followUp="{ item }">
                <v-tooltip bottom v-if="item.followUp && item.followUp.length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                      mdi-tag-text-outline
                    </v-icon>
                  </template>
                  <span>{{ item.followUp }}</span>
                </v-tooltip>
              </template>
              <template v-slot:item.claimStage.name="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{ item.claimStage | formatStage(item) }}
                    </span>
                  </template>
                  <span>{{ item.claimStage.description }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ClosedClaims",
  metaInfo: {
    title: "Closed Claims"
  },
  data() {
    return {
      search: "",
      followUp: false,

      selectedClaimTypeCountIndex: 0,
      selectedClaimTypeCount: undefined,
      claimTypeCounts: [],

      selectedClaimStageCountIndex: 0,
      selectedClaimStageCount: undefined,
      claimStageCounts: [],

      selectedAwardVehicleCountIndex: 0,
      selectedAwardVehicleCount: undefined,
      awardVehicleCounts: [],

      selectedOrganizationCountIndex: 0,
      selectedOrganizationCount: undefined,
      organizationCounts: [],

      loading: false,
      items: [],
      nameFilter: "",
      headers: [
        {
          value: "claimKey",
          text: "Key",
          sortable: true
        },
        {
          value: "promotion.promotionType.name",
          text: "Type",
          sortable: true
        },
        {
          value: "promotion.name",
          text: "Promotion",
          sortable: true
        },
        {
          value: "participant.user.firstName",
          text: "Name",
          sortable: true
        },
        {
          value: "calculatedTotalClaimAmount",
          text: "Amount",
          align: "right",
          sortable: true
        },

        {
          value: "claimStage.name",
          text: "Stage",
          sortable: false
        },
        {
          value: "updatedDate",
          text: "Last Updated",
          sortable: true
        },
        {
          value: "createdDate",
          text: "Age",
          sortable: true
        },
        {
          value: "followUp"
        }
      ],
      total: 0,
      options: {
        itemsPerPage: 10,
        sortBy: ["updatedDate"],
        sortDesc: [true]
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  methods: {
    onClear() {
      this.search = "";
      this.getData();
    },
    onSubmit() {
      this.options.page = 1;
      this.getData();
    },
    onClearSearch() {
      this.search = "";
      this.onSubmit();
    },
    onClearClaimTypeCount() {
      this.selectedClaimTypeCount = undefined;
      this.selectedClaimTypeCountIndex = 0;
      this.selectedClaimStageCount = undefined;
      this.selectedClaimStageCountIndex = 0;
      this.selectedAwardVehicleCount = undefined;
      this.selectedAwardVehicleCount = 0;
      this.selectedOrganizationCount = undefined;
      this.selectedOrganizationCountIndex = 0;
      this.getData();
    },
    onSelectClaimTypeCount(claimTypeCount) {
      if (claimTypeCount && claimTypeCount.claimTypeId) {
        this.selectedClaimTypeCount = claimTypeCount;
        this.getData();
      } else {
        this.onClearClaimTypeCount();
      }
    },
    onClearClaimStageCount() {
      this.selectedClaimStageCount = undefined;
      this.selectedClaimStageCountIndex = 0;
      this.getData();
    },
    onSelectClaimStageCount(claimStageCount) {
      if (claimStageCount && claimStageCount.claimStageId) {
        this.selectedClaimStageCount = claimStageCount;
        this.getData();
      } else {
        this.onClearClaimStageCount();
      }
    },
    onClearAwardVehicleCount() {
      this.selectedAwardVehicleCount = undefined;
      this.selectedAwardVehicleCountIndex = 0;
      this.getData();
    },
    onSelectAwardVehicleCount(awardVehicle) {
      if (awardVehicle && awardVehicle.awardVehicleId) {
        this.selectedAwardVehicleCount = awardVehicle;
        this.getData();
      } else {
        this.onClearAwardVehicleCount();
      }
    },
    onClearOrganizationCount() {
      this.selectedOrganizationCount = undefined;
      this.selectedOrganizationCountIndex = 0;
      this.getData();
    },
    onSelectOrganizationCount(organizationCount) {
      if (organizationCount && organizationCount.organizationId) {
        this.selectedOrganizationCount = organizationCount;
        this.getData();
      } else {
        this.onClearOrganizationCount();
      }
    },
    getData() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let filters = { submitted: true, closed: true };
      if (this.search && this.search.length > 0) {
        filters.keyword = this.search;
      }

      if (this.selectedClaimTypeCount) {
        filters.claimType = { id: this.selectedClaimTypeCount.claimTypeId };
      }

      if (this.selectedClaimTypeCount && this.selectedClaimStageCount) {
        filters.claimStage = { id: this.selectedClaimStageCount.claimStageId };
      }

      if (this.selectedClaimTypeCount && this.selectedOrganizationCount) {
        filters.organization = { id: this.selectedOrganizationCount.organizationId };
      }

      if (this.selectedClaimTypeCount && this.selectedAwardVehicleCount) {
        filters.awardVehicle = { id: this.selectedAwardVehicleCount.awardVehicleId };
      }
      if (this.followUp) {
        filters.followUp = this.followUp;
      }

      this.$api
        .post(
          "/api/claims/search?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.loading = false;
          this.items = data.content;
          this.total = data.totalElements;
        })
        .catch(() => {
          this.loading = false;
        });

      this.$api
        .post(
          "/api/claims/search/byOrganization?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : ""),
          filters
        )
        .then(({ data }) => {
          this.organizationCounts = data;
          this.organizationCounts.unshift({ organizationName: "All" });
        })
        .catch(error => {
          console.log(error);
        });

      this.$api
        .post("/api/claims/search/byClaimStage?size=20", filters)
        .then(({ data }) => {
          this.claimStageCounts = data;
          this.claimStageCounts.unshift({ claimStageName: "All", claimStageRank: -1 });
          this.claimStageCounts.sort((a, b) => a.claimStageRank - b.claimStageRank);
        })
        .catch(error => {
          console.log(error);
        });

      this.$api
        .post("/api/claims/search/byClaimType?size=20", filters)
        .then(({ data }) => {
          this.claimTypeCounts = data;
          this.claimTypeCounts.unshift({ claimTypeName: "All" });
        })
        .catch(error => {
          console.log(error);
        });

      this.$api
        .post("/api/claims/search/byClaimCountsByAwardVehicle?size=20", filters)
        .then(({ data }) => {
          this.awardVehicleCounts = data;
          this.awardVehicleCounts.unshift({ awardVehicleName: "All" });
        })
        .catch(error => {
          console.log(error);
        });
    },
    toClaimDetailManagement(item) {
      let object = {};
      object.name = "cprClaimDetailManagement";
      object.query = this.getParamsFilters(item);
      return object;
    },
    getParamsFilters(item) {
      let params = {};

      params.claim = item.id;

      if (this.search && this.search.length > 0) {
        params.keyword = this.search;
      }

      if (this.selectedClaimTypeCount) {
        params.claimTypeId = this.selectedClaimTypeCount.claimTypeId;

        if (this.selectedClaimStageCount) {
          params.claimStageId = this.selectedClaimStageCount.claimStageId;
        }

        if (this.selectedOrganizationCount) {
          params.organizationId = this.selectedOrganizationCount.organizationId;
        }

        if (this.selectedAwardVehicleCount) {
          params.awardVehicleId = this.selectedAwardVehicleCount.awardVehicleId;
        }
      }

      if (this.followUp) {
        params.followUp = this.followUp;
      }
      params.currentPosition = this.getCurrentElement(item);

      params.page = this.options.page;
      params.total = this.options.itemsPerPage;
      params.itemsPerPage = this.options.itemsPerPage;
      params.sortBy = this.options.sortBy[0];
      params.sortDesc = this.options.sortDesc[0];

      params.closed = true;

      return params;
    },
    getCurrentElement(item) {
      if (this.options.page > 0) {
        return this.items.indexOf(item);
      }
      return (this.options.page - 1) * this.options.itemsPerPage + this.items.indexOf(item);
    }
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", [
      {
        text: this.$i18n.translate("Dashboard"),
        to: { name: "dashboard" },
        exact: true
      },
      {
        text: "Admin"
      },
      { text: "Closed Claims" }
    ]);
  },
  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"]),
    hasFilters() {
      let check = this.search && this.search.length > 0;
      return check;
    }
  }
};
</script>
